import React from "react"
import classnames from "classnames"
import { Text, Image } from "../Core"

const ImageText = ({
  heading,
  imageId,
  textSection,
  extraText,
  imageTextReverse,
  sideColumnIs
}) => {
  const isTextBeforeImage = classnames({
    "flex-row-reverse": !imageTextReverse
  })
  return (
    <div className="body-sections procedure-image-text">
      {!heading ? (
        <></>
      ) : (
        <div className="columns">
          <div className={`column is-${sideColumnIs}`}></div>
          <div className="column">
            <Text text={heading} />
          </div>
          <div className={`column is-${sideColumnIs}`}></div>
        </div>
      )}

      <div className={`columns is-vcentered ${isTextBeforeImage}`}>
        <div className={`column is-${sideColumnIs}`}></div>
        <div className="column">
          <Image publicId={imageId} />
        </div>

        <div className="column is-1"></div>

        <div className="column">
          <Text text={textSection} />
        </div>
        <div className={`column is-${sideColumnIs}`}></div>
      </div>

      {!extraText ? (
        <></>
      ) : (
        <div className="columns extra-text" style={{ marginTop: "1.5rem" }}>
          <div className={`column is-${sideColumnIs}`}></div>
          <div className="column">
            <Text text={extraText} />
          </div>
          <div className={`column is-${sideColumnIs}`}></div>
        </div>
      )}
    </div>
  )
}

export default ImageText
