import React, { useState, useEffect } from "react"
import { Image } from "../Core"

import { Placeholder } from "cloudinary-react"

export default function ImageStrip({ images }) {
  const [mobilePhotoIndex, setMobilePhotoIndex] = useState()

  useEffect(() => {
    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max))
    }

    let rand = getRandomInt(images.length)
    setMobilePhotoIndex(rand)
  })

  return (
    <div className="image-strip clearfix">
      {images.map((image, i) => {
        return (
          <React.Fragment key={i}>
            <Image
              className={i === mobilePhotoIndex ? "" : "is-hidden-mobile"}
              publicId={image}
              width={1000}
              responsive={false}>
              <Placeholder type="blur"></Placeholder>
            </Image>
          </React.Fragment>
        )
      })}
    </div>
  )
}
