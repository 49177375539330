import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

import TheaterVideo from "@components/Theater/TheaterVideo"
import { Button, ButtonGroup } from "../Button"
import { Image, Text } from "../Core"

function ColumnsWrapper(props) {
  return (
    <div className="columns">
      {!props.isFullWidth && (
        <div className={`column is-${props.sideColumnIs}`}></div>
      )}
      <div className="column">{props.children}</div>
      {!props.isFullWidth && (
        <div className={`column is-${props.sideColumnIs}`}></div>
      )}
    </div>
  )
}

function Heading(props) {
  return (
    <Text
      as={props.headingType}
      text={props.heading}
      style={{
        paddingTop: props.paddingTop,
        paddingBottom: props.paddingBottom,
        textAlign: props.textAlign
      }}
    />
  )
}

function SectionImage(props) {
  return (
    <Image
      wrapperClassName="mt-1 mb-2"
      publicId={props.mainPhotoPublicId}
      responsiveUseBreakpoints="true"
    />
  )
}

function Paragraphs(props) {
  return (
    <Text
      style={{
        paddingTop: props.paddingTop,
        paddingBottom: props.paddingBottom
      }}
      text={props.textSection}
    />
  )
}

class GenericSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props)
    if (this.props.genericSection) {
      var sectionColumns = this.props.genericSection.map((section, i) => {
        switch (section.type) {
          case "heading":
            return <Heading key={i} {...section} />
          case "sectionImage":
            return <SectionImage key={i} {...section} />
          case "paragraphs":
            return <Paragraphs key={i} {...section} />
          case "buttons":
            var buttons = section.buttons.map(buttons => {
              if (buttons.button.youtube) {
                return (
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${buttons.button.youtube}`}
                    controls
                    playing
                    onlyButton
                    buttonClass="mt-0 mx-auto"
                  />
                )
              }
              return <Button key={buttons.button.href} {...buttons.button} />
            })
            return (
              <ButtonGroup key={i} {...section}>
                {buttons}
              </ButtonGroup>
            )
          case "modals":
            // return <Modal {...section} />
            return <div></div>
        }
      })
    }

    var sectionClasses = classNames({
      "body-sections section generic-section": true,
      "color-back": this.props.colorBack
    })

    return (
      <section
        className={sectionClasses}
        style={{
          paddingTop: this.props.paddingTop ? this.props.paddingTop : null,
          paddingBottom: this.props.paddingBottom
            ? this.props.paddingBottom
            : null
        }}>
        <ColumnsWrapper {...this.props}>{sectionColumns}</ColumnsWrapper>
      </section>
    )
  }
}

GenericSection.propTypes = {
  headingSideColumnIs: PropTypes.number,
  isMediaFullWidth: PropTypes.bool,
  videoSideColumnIs: PropTypes.number,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  youtube: PropTypes.string,
  youtubePhotoPublicId: PropTypes.string
}

export default GenericSection
