import React from "react"
import ReactPlayer from "react-player"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Image } from "../components/Core"

function Video({ videoUrl, youtube, language = "en", imageId, isSizzle }) {
  // isInline = play sizzle
  if (isSizzle) {
    return (
      <div style={{ position: "relative", paddingTop: "56.25%" }}>
        <ReactPlayer
          style={{ position: "absolute", top: 0, left: 0 }}
          url={videoUrl}
          width="100%"
          height="100%"
          loop
          playing
          muted
        />
      </div>
    )
  }
  return (
    <TheaterVideo
      videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
      controls
      playing
      hasWatchVideo
      overVideo
      iconOnly
      language={language}>
      <Image cloudName="nuvolum" publicId={imageId} />
    </TheaterVideo>
  )
}

export default Video
